import React, { useContext } from "react"
import ModeContext from "../context/ModeContext"
import loaderStyles from "../styles/loader.module.css"

const Loader = () => {
  const { dark } = useContext(ModeContext)

  return (
    <>
        <div className={loaderStyles.loader}>
            <div className={`${loaderStyles.dot} ${loaderStyles.one} ${dark ? loaderStyles.dark : ''}`}></div>
            <div className={`${loaderStyles.dot} ${loaderStyles.two} ${dark ? loaderStyles.dark : ''}`}></div>
            <div className={`${loaderStyles.dot} ${loaderStyles.three} ${dark ? loaderStyles.dark : ''}`}></div>
            <div className={`${loaderStyles.dot} ${loaderStyles.four} ${dark ? loaderStyles.dark : ''}`}></div>

        </div>

        {/* <div className={loaderStyles.loaderTwo}>
            <div className={`${loaderStyles.redDot} ${loaderStyles.secondOne} ${dark ? loaderStyles.dark : ''}`}></div>
            <div className={`${loaderStyles.redDot} ${loaderStyles.secondTwo} ${dark ? loaderStyles.dark : ''}`}></div>
            <div className={`${loaderStyles.redDot} ${loaderStyles.secondThree} ${dark ? loaderStyles.dark : ''}`}></div>
            <div className={`${loaderStyles.redDot} ${loaderStyles.secondFour} ${dark ? loaderStyles.dark : ''}`}></div>

        </div> */}
    </>
  )
}

export default Loader;