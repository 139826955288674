import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import footerStyles from "../styles/footer.module.css"
import { navigate } from "gatsby"
import weatherService from "../services/weather"

// import Socials from "./socials.js"

const Footer = ({ dark }) => {

  const [weather, setWeather] = useState("")

  const dateObj = new Date();
  let options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
  let date = dateObj.toLocaleDateString("en-us", options).split(',').join('');
  let hours = dateObj.getHours() < 10 ? `0${dateObj.getHours()}` : dateObj.getHours();
  let minutes = dateObj.getMinutes() < 10 ? `0${dateObj.getMinutes()}` : dateObj.getMinutes();
  let seconds = dateObj.getSeconds() < 10 ? `0${dateObj.getSeconds()}` : dateObj.getSeconds();
  let time = hours + ":" + minutes + ":" + seconds;

  const [present, setPresent] = useState(
    {
      time: time,
      date: date
    }
  )

  useEffect(() => {
    getPresent()
    // getAndSetWeather()
  }, [])

  const sup = () => {
    // navigate('/pad/')
  }

  const getAndSetWeather = async () => {
    const weatherData = await weatherService.getWeather()
    setWeather(weatherData)
  }


  const getPresent = () => {

    const dateObj = new Date();
    let hours = dateObj.getHours() < 10 ? `0${dateObj.getHours()}` : dateObj.getHours();
    let minutes = dateObj.getMinutes() < 10 ? `0${dateObj.getMinutes()}` : dateObj.getMinutes();
    let seconds = dateObj.getSeconds() < 10 ? `0${dateObj.getSeconds()}` : dateObj.getSeconds();
    let time = hours + ":" + minutes + ":" + seconds;

    setPresent({
      time,
      date
    })



    setTimeout(() => {
      getPresent()
    }, 1000);
  }


  return (
    <footer className={footerStyles.footer}>
      {/* <Socials dark={dark} /> */}

      <div style={{ width: '6px', height: '6px', borderRadius: '6px', backgroundColor: 'transparent', display: 'block', margin: 'auto', marginBottom: '20px', cursor: 'crosshair' }} onClick={sup}></div>
      {/* <p style={{fontSize: '12px', marginTop: '-4px', opacity: '.75'}}>{'sup dot ...'}</p> */}
      {/* <p style={{ fontSize: '11px', marginTop: '0px', opacity: '.95' }} >{weather ? `${weather.weather.location}` : "..."}</p>
      <p style={{ fontSize: '11px', marginTop: '0px', opacity: '.95' }} >{weather !== "" ? `${weather.weather.temp} and ${weather.weather.desc.toLowerCase()}` : "..."}</p> */}
      {/* <p style={{ position: 'relative', marginBottom: '7px', zIndex: '100' }} onClick={() => { window.history.back() }}>...</p> */}
      <p style={{ fontSize: '14px', fontFamily: 'monospace', opacity: '.95', cursor: 'grab' }}>{`${present.time}`}</p>
      <p style={{ fontSize: '14px', marginTop: '2px', cursor: 'grab' }}>{` ${present.date}`}</p>
    </footer>
  )
}

export default Footer
