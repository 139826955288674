const axios = require("axios")
const weatherUrl = "https://api.sup.cool/weather/"
// const weatherUrl = 'http://localhost:3000/weather'

const getWeather = async () => {

  const coords = {
    coords: window.localStorage.getItem('coords')
    // coords: '53.35,-6.26'
  }
  
  // const weather = await axios.post(weatherUrl, coords)
  const weather = await axios.post(weatherUrl, {coords: `41.85,-88.10`})

  console.log(weather.data)

  const location = weather.data.weather.location;
  // const commaIndex = location.indexOf(',');
  // const locationStr = location.toLowerCase();

  // const weatherStr = `${weather.data.weather.temp} and ${weather.data.weather.desc.toLowerCase()} in ${locationStr}`

  return weather.data
}

export default {getWeather}